export const THEME_COLOR = {
  BACKGROUND: {
    light: '#ffffff',
    dark: '#210545',
  },
  COLOR: {
    light: '#321952',
    dark: '#fff',
  },
};
