import Icon from '@ant-design/icons';
import { ElementType } from 'react';
import {
  ICSend,
  ICPhoneElegant,
  ICEmailElegant,
  ICGrid,
  ICQuestionCircle,
  ICLinkElegant,
  ICEllipsis,
  ICChevronLeft,
  ICChevronRight,
  ICLock,
  ICArrowUpLeft,
  ICHastagLock,
  ICTweenie,
  ICTweenieClassic,
  ICSearch,
  ICCircleXmark,
  ICUpRightFrom,
  ICShrink,
  ICExpand,
  ICHide,
  ICArrowUpRIght,
  ICStars,
} from './svg';

interface IconProps {
  SVGComponent: ElementType;
  svgProps?: any;
}

const IconWrapper = ({ SVGComponent, svgProps, ...props }: IconProps) => (
  <Icon
    {...props}
    component={() => <SVGComponent {...svgProps} fill="none" />}
  />
);

export const GridIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICGrid} />
);

export const QuestionCircleIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICQuestionCircle} />
);

export const SendIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICSend} />
);

export const PhoneElegantIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICPhoneElegant} />
);

export const EmailElegantIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICEmailElegant} />
);

export const LinkElegantIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICLinkElegant} />
);

export const EllipsisIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICEllipsis} />
);

export const ChevronLeftIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICChevronLeft} />
);

export const ChevronRightIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICChevronRight} />
);

export const LockIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICLock} />
);

export const ArrowUpLeftIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICArrowUpLeft} />
);

export const ArrowUpRightIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICArrowUpRIght} />
);

export const StarsIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICStars} />
);

export const HastagLockIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICHastagLock} />
);

export const TweenieIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICTweenie} />
);

export const TweenieClassicIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICTweenieClassic} />
);

export const SearchIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICSearch} />
);

export const CircleXmarkIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICCircleXmark} />
);

export const UpRightFromIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICUpRightFrom} />
);

export const ShrinkIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICShrink} />
);

export const ExpandIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICExpand} />
);

export const HideIcon = (props: any) => (
  <IconWrapper {...props} SVGComponent={ICHide} />
);
