import { WrapButtonDisplay } from './styles';
import LottieIcon from '../LottieIcon';
import { HideIcon } from '../SVGIcons';
import { ColorBrightnessProps, getColorBrightness } from 'utils/tools';

export interface IButtonDisplay {
  onClick?: () => void;
  openChatBox?: boolean;
  data?: any;
}

export default function ButtonDisplay({
  onClick = () => {},
  openChatBox = false,
  data,
}: IButtonDisplay) {
  const widgetThemeColor = getColorBrightness(
    '#eeece8'
  ) as ColorBrightnessProps;

  return (
    <WrapButtonDisplay
      screenPosition={data?.metaData?.position || 'right'}
      buttonStyle="light"
      // buttonStyle={(dataCustomization?.buttonTheme || 'light') as ButtonTheme}
      widgetThemeColor={widgetThemeColor}
    >
      <div className="buttonDisplay-empty" />
      <div
        className={`buttonDisplay ${openChatBox ? 'type-open' : `type-icon`} ${
          data?.metaData?.buttonDisplay || 'icon_only'
        }`}
        onClick={onClick}
      >
        {openChatBox ? (
          <>
            <div className="hide-button">
              <HideIcon />
            </div>
          </>
        ) : (
          <>
            {(data?.metaData?.buttonDisplay === 'icon_text' ||
              data?.metaData?.buttonDisplay === 'icon_text_docked') && (
              <>
                <LottieIcon size={32} />
                <div className="buttonDisplay-wrapText">
                  <div className="size-12 fw-500 buttonDisplay-text">
                    Chat now
                  </div>
                </div>
              </>
            )}
            {/* {(data?.metaData?.buttonDisplay === 'icon_only') && (
              <LottieIcon size={64} />
            )} */}
            {(!data?.metaData?.buttonDisplay ||
              data?.metaData?.buttonDisplay === 'icon_only') && (
              <LottieIcon size={64} />
            )}
          </>
        )}
      </div>
    </WrapButtonDisplay>
  );
}
