import styled, { css, keyframes } from 'styled-components';
import { Step } from 'types';

const bounceInFormRight = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    translate3d(0, 0, 0);
  }
  //  0% {
  //   opacity: 0;
  //   transform: scale(0, 0);
  //   transform-origin: bottom right;
  //   height: 0;
    
  //  }
  //  50% {
  //     transform: scale(1.03, 1.03);
  //   transform-origin: bottom right;
  //   height: auto;

  //  }
  //  100% {
  //     opacity: 1;
  //   transform: scale(1, 1);
  //   transform-origin: bottom right;

  //  }
`;

const bounceOutFormRight = keyframes`
  0% {
    opacity: 1;
    translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    display: none;
  }
  //  0% {
  //     opacity: 1;
  //   transform: scale(1, 1);
  //   transform-origin: bottom right;
  //  }
  //  60% {
  //   opacity: 0;
  //  }
  //  100% {
  //     opacity: 0;
  //   transform: scale(0, 0);
  //   transform-origin: bottom right;
  //   height: 0;
  //   width: 0;
  //  }
`;

const bounceInFormLeft = keyframes`
   0% {
      opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom left;
    height: 0;
  
   }
   50% {
      transform: scale(1.03, 1.03);
    transform-origin: bottom left;
    height: auto;

   }
   100% {
      opacity: 1;
    transform: scale(1, 1);
    transform-origin: bottom left;

   }
`;

const bounceOutFormLeft = keyframes`
   0% {
      opacity: 1;
    transform: scale(1, 1);
    transform-origin: bottom left;

   }
   100% {
      opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom left;
    height: 0;
    width: 0;
   }
`;

const bottomToTop = keyframes`
0% {
  opacity: 0;
  transform: translateY(100%);
  height: 0;

}
100% {
opacity: 1;
transform: translateY(0);
height: auto
}
`;

const topToBottom = keyframes`
0% {
  opacity: 1;
  transform: translateY(0);
  height: auto;
}
100% {
opacity: 0;
transform: translateY(100%);
height: 0;
width: 0;
}
`;

export const ChatBoxContainerStyle = styled.div<{
  marginDesktop?: string;
  marginMobile?: string;
  isOpenChatBox?: boolean;
  process: Step;
  buttonDisplay?: string;
  position?: string;
  hasEmbedWebsite?: boolean;
}>`
  .chatBox-mask {
    display: none;

    @media (max-width: 576px) {
      ${({ isOpenChatBox }) =>
        isOpenChatBox &&
        `
        display: block;
      `}
      width: 100vh;
      height: 100vh;
      background: #00000045;
    }
  }
  #app-builder {
    filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 14px);
    transform: translateZ(0px);
    background-color: rgb(254, 237, 229);
    border: none;
    overflow: hidden;
    border-radius: 25px;
    width: 375px;
    height: 530px;
    max-height: calc(100vh - 200px);

    @media (max-width: 576px) {
      width: calc(100vw - 20px);
      width: calc(100svw - 20px);
      height: calc(100vh - 200px);
      height: calc(100svh - 200px);
    }
  }
  &.ctn-chatBox {
    &-entire {
      position: fixed;
      opacity: 0;
      transition: all 0.3s linear;
      z-index: 9999;

      &.position-left {
        opacity: 1;
        ${({ marginDesktop, buttonDisplay, isOpenChatBox }) => css`
          left: ${marginDesktop || 15}px;
          // bottom: ${marginDesktop || 15}px;
          bottom: ${buttonDisplay === 'icon_text_docked' && !isOpenChatBox
            ? 0
            : marginDesktop || 15}px;
        `}

        @media (max-width: 575px) {
          left: 0;
          bottom: 0;
          right: 0;
        }
      }

      &.position-right {
        opacity: 1;
        ${({
          marginDesktop,
          hasEmbedWebsite,
          buttonDisplay,
          isOpenChatBox,
        }) => css`
          // right: ${+(marginDesktop || 15) + (hasEmbedWebsite ? 10 : 0)}px;
          right: ${marginDesktop || 15}px;
          // bottom: ${marginDesktop || 15}px;
          bottom: ${buttonDisplay === 'icon_text_docked' && !isOpenChatBox
            ? 0
            : marginDesktop || 15}px;
        `}

        @media (max-width: 575px) {
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }

  .ctn-chatBox {
    &-chatBoxMain {
      animation-duration: 350ms;
      animation-timing-function: ease-in;
      margin-bottom: 10px;
      position: relative;

      .loading-wrapper {
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .anticon {
          font-size: 30px;
        }
      }

      .ant-drawer {
        position: absolute;
        border-radius: 20px;
        overflow: hidden;

        .ant-drawer-content-wrapper {
          left: 10px;
          bottom: 10px;
          right: 10px;
        }
      }

      ${({ isOpenChatBox }) =>
        isOpenChatBox === undefined &&
        css`
          display: none;
        `}

      &.open-right {
        animation-name: ${({ buttonDisplay }) =>
          buttonDisplay === 'text' ? bottomToTop : bounceInFormRight};
        animation-fill-mode: backwards;
      }

      &.hidden-right {
        animation-name: ${({ buttonDisplay }) =>
          buttonDisplay === 'text' ? topToBottom : bounceOutFormRight};
        animation-fill-mode: forwards;
      }

      &.open-left {
        animation-name: ${({ buttonDisplay }) =>
          buttonDisplay === 'text' ? bottomToTop : bounceInFormLeft};
        animation-fill-mode: backwards;
      }

      &.hidden-left {
        animation-name: ${({ buttonDisplay }) =>
          buttonDisplay === 'text' ? topToBottom : bounceOutFormLeft};
        animation-fill-mode: forwards;
      }

      @media (max-width: 575px) {
        position: absolute;
        // left: 0;
        right: 10px;
        ${({ marginMobile }) => `
          bottom: ${marginMobile}px;
        `}
        z-index: 2;
        margin-bottom: 0;

        ${({ process }) =>
          process === 'chat' &&
          css`
            position: relative;
            z-index: 2;
          `}
      }
    }

    &-temporaryTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 22px;
      padding: 11px 16px 11px 14px;
      filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.1));
      width: max-content;
      position: absolute;
      // top: -56px;
      bottom: 64px;
      ${({ buttonDisplay }) =>
        (buttonDisplay === 'icon_text' ||
          buttonDisplay === 'icon_text_docked') &&
        `
        bottom: 50px;
      `}
      ${({ buttonDisplay }) =>
        buttonDisplay === 'icon_text_docked' &&
        `
        bottom: 50px;
      `}

      ${({ position }) =>
        position === 'right'
          ? css`
              right: 0;
            `
          : css`
              left: 0;
            `}

      &-companyLogo {
        border-radius: 50%;
        margin-right: 9px;
      }
    }

    &-startLiveChat {
      display: flex;
      justify-content: flex-end;

      &.position-left {
        justify-content: flex-start;
      }

      @media (max-width: 575px) {
        position: absolute;

        &.position-left {
          ${({ marginMobile, buttonDisplay, isOpenChatBox }) => css`
            left: ${marginMobile || 15}px;
            // bottom: ${marginMobile || 15}px;
            bottom: ${buttonDisplay === 'icon_text_docked' && !isOpenChatBox
              ? 0
              : marginMobile || 15}px;
          `}
        }

        &.position-right {
          ${({
            marginMobile,
            hasEmbedWebsite,
            buttonDisplay,
            isOpenChatBox,
          }) => css`
            // right: ${+(marginMobile || 15) + (hasEmbedWebsite ? 10 : 0)}px;
            right: ${marginMobile || 15}px;
            bottom: ${buttonDisplay === 'icon_text_docked' && !isOpenChatBox
              ? 0
              : marginMobile || 15}px;
          `}
        }
      }
    }
  }
  /* .ctn-chatBox-entire {
    width: ${({ isOpenChatBox }) => (isOpenChatBox ? 'auto' : '0px')};
  } */
`;
