import React, { useEffect, useState } from 'react';
import { ChatBoxContainerStyle } from './styles';
import ButtonDisplay from 'components/atoms/ButtonDisplay';
import { LoadingOutlined } from '@ant-design/icons';
import { get } from 'api/utils';

// const CHATBOX_HEIGHT_NOCOVERIMAGE = 381;
// const CHATBOX_HEIGHT_COVERIMAGE = 351;

interface ChatBoxContainerProps {
  hasEmbedWebsite?: boolean;
}

const ChatBoxContainer: React.FC<ChatBoxContainerProps> = ({
  hasEmbedWebsite = false,
}) => {
  const [isOpenChatBox, setIsOpenChatBox] = useState<boolean>();
  // const { data } = useQuery(['chat-detail-key', window.devtifyId], () =>
  //   get(`/chat-interfaces/${window.devtifyId}`)
  // );
  const [dataChatDetail, setDataChatDetail] = useState<any>();
  const position = dataChatDetail?.metaData?.position || 'right';

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const readyInit = () => {
        // eslint-disable-next-line
        if ((event as any)?.data?.action === 'ready-init') {
          get(`/chat-interfaces/app/${window.devtifyId}`).then((res) => {
            setDataChatDetail(res);
          });
          setLoading(false);
          // initAuth();
        }
        // eslint-disable-next-line
        if ((event as any)?.data?.action === 'reload') {
          window.location.reload();
        }
      };
      window.addEventListener('message', readyInit, false);
    } catch (error) {
      //
    }
  }, []);

  return (
    <ChatBoxContainerStyle
      hasEmbedWebsite={hasEmbedWebsite}
      marginDesktop={dataChatDetail?.metaData?.marginDesktop || '20'}
      marginMobile={dataChatDetail?.metaData?.marginMobile || '20'}
      isOpenChatBox={isOpenChatBox}
      process="getStarted"
      buttonDisplay={dataChatDetail?.metaData?.buttonDisplay || 'icon_only'}
      position={position}
      // className="ctn-chatBox-entire  position-right"
      className={`ctn-chatBox-entire ${position && `position-${position}`}`}
    >
      <div className="chatBox-mask" onClick={() => setIsOpenChatBox(false)} />
      <div
        id="diaflow-wrapper"
        className={`ctn-chatBox-chatBoxMain ${
          isOpenChatBox ? 'open' : 'hidden'
        }-right`}
      >
        {loading && (
          <div className="loading-wrapper">
            <LoadingOutlined />
          </div>
        )}
        <iframe
          title="Diaflow chat app"
          id="app-builder"
          allow="clipboard-read; clipboard-write; microphone;camera"
          src={`${process.env.REACT_APP_BUILDER_APP_URL}/chat/${window.devtifyId}`}
          // width="375px"
          // height="530px"
        />
      </div>
      <div
        // className="ctn-chatBox-startLiveChat position-right"
        className={`ctn-chatBox-startLiveChat ${
          position && `position-${position}`
        }`}
      >
        {dataChatDetail?.metaData?.prominenceType === 'button_and_text' &&
          dataChatDetail?.metaData?.prominenceText &&
          !isOpenChatBox && (
            <div className="ctn-chatBox-temporaryTitle">
              <div className="size-12 black-color fw-400">
                {dataChatDetail?.metaData.prominenceText}
              </div>
            </div>
          )}
        {dataChatDetail?.id && (
          <ButtonDisplay
            onClick={() => {
              setIsOpenChatBox(!isOpenChatBox);
            }}
            openChatBox={isOpenChatBox}
            data={dataChatDetail}
          />
        )}
      </div>
    </ChatBoxContainerStyle>
  );
};

export default ChatBoxContainer;
