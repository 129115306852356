export const makeLighterColor = (color?: string, opacity = 0.1) => {
  const hex = color?.replace('#', '') || '';
  const hexR = `0x${hex.substring(0, 2)}` as any;
  const hexG = `0x${hex.substring(2, 4)}` as any;
  const hexB = `0x${hex.substring(4, 6)}` as any;
  const r = Math.floor(hexR * opacity + 0xff * (1 - opacity));
  const g = Math.floor(hexG * opacity + 0xff * (1 - opacity));
  const b = Math.floor(hexB * opacity + 0xff * (1 - opacity));

  const colorWithoutOpacity = '#' + ((r << 16) | (g << 8) | b).toString(16);

  return colorWithoutOpacity || '';
};

export const makeDarkerColor = (
  color?: string,
  opacity = 0.2,
  suffix = 0x0e
) => {
  const hex = color?.replace('#', '') || '';

  const hexR = `0x${hex.substring(0, 2)}` as any;
  const hexG = `0x${hex.substring(2, 4)}` as any;
  const hexB = `0x${hex.substring(4, 6)}` as any;
  const r = Math.floor(hexR * opacity + suffix * (1 - opacity));
  const g = Math.floor(hexG * opacity + suffix * (1 - opacity));
  const b = Math.floor(hexB * opacity + suffix * (1 - opacity));

  const colorWithoutOpacity = '#' + ((r << 16) | (g << 8) | b).toString(16);

  return colorWithoutOpacity || '';
};

export const getColorBrightness = (color?: string, type = 'creative') => {
  if (!color) return null;
  const hex = color?.replace('#', '') || '';
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

  if (type === 'modern') {
    return {
      brightness,
      dividerColor:
        brightness > 155
          ? makeDarkerColor(color)
          : makeLighterColor(color, 0.05),
      avatarColor:
        brightness > 155
          ? makeDarkerColor(color, 0.5)
          : makeLighterColor(color, 0.7),
      backgroundColor:
        brightness > 155 ? makeDarkerColor(color) : makeLighterColor(color),
      chatbotTextColor:
        brightness > 155
          ? makeLighterColor(color, 0.7)
          : makeLighterColor(color, 0.7),
      buttonColor:
        brightness > 155
          ? makeDarkerColor(color, 0.3)
          : makeLighterColor(color, 0.2),
      secondButtonColor:
        brightness > 155
          ? makeDarkerColor(color, 0.3)
          : makeLighterColor(color, 0.2),
      suggestColor:
        brightness > 155
          ? makeDarkerColor(color, 0.3)
          : makeLighterColor(color, 0.2),
      borderColor:
        brightness > 155
          ? makeDarkerColor(color)
          : makeLighterColor(color, 0.05),
      textColor: color,
      secondTextColor: color,
      suggestTextColor: color,
      minimizeIconColor: brightness > 155 ? makeDarkerColor(color) : color,
    };
  }
  if (type === 'creative') {
    return {
      brightness,
      avatarColor:
        brightness > 155
          ? makeDarkerColor(color, 0.5)
          : makeLighterColor(color, 0.7),
      chatbotTextColor:
        brightness > 155
          ? makeDarkerColor(color, 0.2)
          : makeLighterColor(color, 0.4),
      textColor:
        brightness > 155 ? makeDarkerColor(color) : makeLighterColor(color),
      secondTextColor:
        brightness > 155 ? makeDarkerColor(color) : makeLighterColor(color),
      suggestTextColor:
        brightness > 155 ? makeDarkerColor(color) : makeLighterColor(color),
      buttonColor:
        brightness > 155
          ? makeDarkerColor(color, 0.9, 0x3e)
          : makeLighterColor(color, 0.8),
      secondButtonColor:
        brightness > 155
          ? makeDarkerColor(color, 0.9, 0x3e)
          : makeLighterColor(color, 0.8),
      suggestColor:
        brightness > 155
          ? makeDarkerColor(color, 0.9, 0x3e)
          : makeLighterColor(color, 0.8),
      ...(brightness > 220 && {
        buttonColor: makeDarkerColor(color, 0.95, 0x3e),
        secondButtonColor: makeDarkerColor(color, 0.95, 0x3e),
        suggestColor: makeDarkerColor(color, 0.95, 0x3e),
      }),
      backgroundColor: color,
      borderColor: color,
      minimizeIconColor: brightness > 155 ? makeDarkerColor(color) : color,
      dividerColor:
        brightness > 155
          ? makeDarkerColor(color, 0.95)
          : makeLighterColor(color, 0.8),
    };
  }

  return {
    brightness,
    avatarColor: '',
    textColor:
      brightness > 155 ? makeDarkerColor(color) : makeLighterColor(color),
    chatbotTextColor:
      brightness > 155 ? makeDarkerColor(color) : makeLighterColor(color),
    secondTextColor: '#232D42',
    buttonColor: color,
    ...(brightness === 255 && {
      buttonColor: '#e6e6e6',
    }),
    secondButtonColor: '#e6e6e6',
    suggestColor:
      brightness > 155
        ? makeDarkerColor(color, 0.2)
        : makeLighterColor(color, 0.2),
    suggestTextColor: color,
    backgroundColor: '#fff',
    borderColor: '#fff',
    dividerColor: '#00643C14',
    minimizeIconColor: '#232D42',
  };
};

export type ColorBrightnessProps = {
  brightness: number;
  backgroundColor: string;
  textColor: string;
  secondTextColor: string;
  suggestColor: string;
  suggestTextColor: string;
  chatbotTextColor: string;
  buttonColor: string;
  secondButtonColor: string;
  avatarColor: string;
  borderColor: string;
  dividerColor?: string;
  minimizeIconColor?: string;
};
