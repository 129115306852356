import React, { useEffect, useState } from 'react';
import './App.css';
import 'styles/global.css';
import 'styles/layout.css';
import ChatBoxContainer from 'container/ChatBox';

const getChatbotDataFromUrl = (searchStr?: string) => {
  let params = new URLSearchParams(searchStr);
  // let foo = params.get('query');
  return {
    diaflowPreviewUrl: params.get('diaflowPreviewUrl'),
    diaflowId: params.get('diaflowId'),
  };
};

function App() {
  // useInitSession(window.devtifyId);
  const data = getChatbotDataFromUrl(window.location.search);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (data?.diaflowId) {
      window.devtifyId = data?.diaflowId;
      setReady(true);
    }
  }, [data?.diaflowId]);

  return (
    <div>
      {data?.diaflowPreviewUrl && (
        <iframe
          src={data?.diaflowPreviewUrl}
          title="Preview widget"
          id="website-iframe"
          style={{ width: '100vw', height: '100vh' }}
        />
      )}
      {(window.devtifyId || ready) && (
        <ChatBoxContainer hasEmbedWebsite={!!data?.diaflowPreviewUrl} />
      )}
    </div>
  );
}

export default App;
