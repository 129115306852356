export const ICGrid = (props: any) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.1875 1.9375V4.5625H4.8125V1.9375H2.1875ZM0.875 1.9375C0.875 1.22656 1.44922 0.625 2.1875 0.625H4.8125C5.52344 0.625 6.125 1.22656 6.125 1.9375V4.5625C6.125 5.30078 5.52344 5.875 4.8125 5.875H2.1875C1.44922 5.875 0.875 5.30078 0.875 4.5625V1.9375ZM2.1875 8.9375V11.5625H4.8125V8.9375H2.1875ZM0.875 8.9375C0.875 8.22656 1.44922 7.625 2.1875 7.625H4.8125C5.52344 7.625 6.125 8.22656 6.125 8.9375V11.5625C6.125 12.3008 5.52344 12.875 4.8125 12.875H2.1875C1.44922 12.875 0.875 12.3008 0.875 11.5625V8.9375ZM11.8125 1.9375H9.1875V4.5625H11.8125V1.9375ZM9.1875 0.625H11.8125C12.5234 0.625 13.125 1.22656 13.125 1.9375V4.5625C13.125 5.30078 12.5234 5.875 11.8125 5.875H9.1875C8.44922 5.875 7.875 5.30078 7.875 4.5625V1.9375C7.875 1.22656 8.44922 0.625 9.1875 0.625ZM9.1875 8.9375V11.5625H11.8125V8.9375H9.1875ZM7.875 8.9375C7.875 8.22656 8.44922 7.625 9.1875 7.625H11.8125C12.5234 7.625 13.125 8.22656 13.125 8.9375V11.5625C13.125 12.3008 12.5234 12.875 11.8125 12.875H9.1875C8.44922 12.875 7.875 12.3008 7.875 11.5625V8.9375Z" />
  </svg>
);
export const ICQuestionCircle = (props: any) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.6875 7.75C12.6875 5.72656 11.5938 3.86719 9.84375 2.82812C8.06641 1.81641 5.90625 1.81641 4.15625 2.82812C2.37891 3.86719 1.3125 5.72656 1.3125 7.75C1.3125 9.80078 2.37891 11.6602 4.15625 12.6992C5.90625 13.7109 8.06641 13.7109 9.84375 12.6992C11.5938 11.6602 12.6875 9.80078 12.6875 7.75ZM0 7.75C0 5.26172 1.3125 2.96484 3.5 1.70703C5.66016 0.449219 8.3125 0.449219 10.5 1.70703C12.6602 2.96484 14 5.26172 14 7.75C14 10.2656 12.6602 12.5625 10.5 13.8203C8.3125 15.0781 5.66016 15.0781 3.5 13.8203C1.3125 12.5625 0 10.2656 0 7.75ZM4.62109 5.28906C4.83984 4.66016 5.41406 4.25 6.07031 4.25H7.65625C8.61328 4.25 9.40625 5.04297 9.40625 6C9.40625 6.60156 9.05078 7.17578 8.53125 7.47656L7.65625 7.99609C7.62891 8.35156 7.35547 8.625 7 8.625C6.61719 8.625 6.34375 8.35156 6.34375 7.96875V7.61328C6.34375 7.36719 6.45312 7.14844 6.67188 7.03906L7.875 6.35547C8.01172 6.27344 8.09375 6.13672 8.09375 6C8.09375 5.75391 7.90234 5.58984 7.65625 5.58984H6.07031C5.98828 5.58984 5.90625 5.64453 5.87891 5.72656L5.85156 5.75391C5.74219 6.10938 5.35938 6.27344 5.03125 6.16406C4.67578 6.02734 4.51172 5.64453 4.62109 5.31641V5.28906ZM6.125 10.375C6.125 9.91016 6.50781 9.5 7 9.5C7.46484 9.5 7.875 9.91016 7.875 10.375C7.875 10.8672 7.46484 11.25 7 11.25C6.50781 11.25 6.125 10.8672 6.125 10.375Z" />
  </svg>
);
export const ICSend = (props: any) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.11719 5.4375H9.97656L2.52344 2.25L4.11719 5.4375ZM4.11719 6.5625L2.52344 9.77344L9.97656 6.5625H4.11719ZM2.03125 0.820312L12.5312 5.32031C12.8125 5.4375 13 5.71875 13 6.02344C13 6.30469 12.8125 6.58594 12.5312 6.70312L2.03125 11.2031C1.75 11.3203 1.39844 11.25 1.1875 11.0156C0.976562 10.8047 0.929688 10.4531 1.07031 10.1719L3.15625 6L1.07031 1.85156C0.929688 1.57031 0.976562 1.21875 1.1875 0.984375C1.39844 0.773438 1.75 0.703125 2.03125 0.820312Z" />
  </svg>
);

export const ICEmailElegant = (props: any) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.75 1.3125C1.50391 1.3125 1.3125 1.53125 1.3125 1.75V2.37891L6.01562 6.23438C6.58984 6.69922 7.38281 6.69922 7.95703 6.23438L12.6875 2.37891V1.75C12.6875 1.53125 12.4688 1.3125 12.25 1.3125H1.75ZM1.3125 4.07422V8.75C1.3125 8.99609 1.50391 9.1875 1.75 9.1875H12.25C12.4688 9.1875 12.6875 8.99609 12.6875 8.75V4.07422L8.80469 7.24609C7.73828 8.12109 6.23438 8.12109 5.19531 7.24609L1.3125 4.07422ZM0 1.75C0 0.792969 0.765625 0 1.75 0H12.25C13.207 0 14 0.792969 14 1.75V8.75C14 9.73438 13.207 10.5 12.25 10.5H1.75C0.765625 10.5 0 9.73438 0 8.75V1.75Z" />
  </svg>
);

export const ICPhoneElegant = (props: any) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.2539 7.79688L13.3164 9.10938C13.8086 9.30078 14.082 9.82031 13.9727 10.3398L13.3164 13.4023C13.207 13.8945 12.7422 14.2773 12.25 14.2773C12.0586 14.2773 11.8945 14.25 11.7305 14.25C11.457 14.25 11.1836 14.2227 10.9375 14.1953C4.78516 13.5391 0 8.34375 0 2C0 1.50781 0.355469 1.04297 0.847656 0.933594L3.91016 0.277344C4.42969 0.167969 4.94922 0.441406 5.14062 0.933594L6.45312 3.99609C6.64453 4.43359 6.53516 4.95312 6.15234 5.25391L5.03125 6.18359C5.76953 7.44141 6.80859 8.48047 8.06641 9.21875L8.99609 8.09766C9.29688 7.71484 9.81641 7.60547 10.2539 7.79688ZM12.0586 12.9375L12.6328 10.2305L9.89844 9.05469L9.10547 10.0391C8.69531 10.5312 7.98438 10.668 7.41016 10.3398C5.96094 9.49219 4.75781 8.28906 3.91016 6.83984C3.58203 6.26562 3.71875 5.55469 4.21094 5.14453L5.19531 4.35156L4.01953 1.61719L1.3125 2.19141C1.39453 8.09766 6.15234 12.8555 12.0586 12.9375Z" />
  </svg>
);

export const ICLinkElegant = (props: any) => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.8594 7.57812L12.7695 10.668C11.2109 12.1992 8.72266 12.1992 7.19141 10.668C5.74219 9.19141 5.66016 6.86719 7 5.30859L7.13672 5.14453C7.38281 4.87109 7.79297 4.84375 8.06641 5.08984C8.33984 5.33594 8.36719 5.74609 8.12109 6.01953L8.01172 6.15625C7.08203 7.19531 7.13672 8.75391 8.12109 9.73828C9.13281 10.75 10.8008 10.75 11.8398 9.73828L14.9297 6.64844C15.9414 5.60938 15.9414 3.96875 14.9297 2.92969C13.9453 1.97266 12.3867 1.91797 11.3477 2.82031L11.1836 2.95703C10.9102 3.20312 10.5 3.17578 10.2539 2.90234C10.0078 2.62891 10.0352 2.21875 10.3086 1.97266L10.4727 1.83594C12.0312 0.46875 14.3828 0.550781 15.8594 2C17.3906 3.53125 17.3906 6.01953 15.8594 7.57812ZM1.61328 6.94922L4.73047 3.85938C6.26172 2.32812 8.75 2.32812 10.2812 3.85938C11.7578 5.30859 11.8398 7.66016 10.4727 9.21875L10.3086 9.38281C10.0898 9.65625 9.65234 9.71094 9.37891 9.46484C9.10547 9.21875 9.07812 8.80859 9.32422 8.53516L9.48828 8.37109C10.3906 7.33203 10.3359 5.77344 9.35156 4.78906C8.33984 3.75 6.67188 3.75 5.66016 4.78906L2.54297 7.87891C1.53125 8.89062 1.53125 10.5586 2.54297 11.5977C3.52734 12.5547 5.08594 12.6094 6.125 11.707L6.28906 11.5703C6.5625 11.3242 6.97266 11.3516 7.21875 11.625C7.46484 11.8984 7.4375 12.3086 7.16406 12.5547L7 12.6914C5.44141 14.0586 3.08984 13.9766 1.61328 12.5273C0.0820312 10.9961 0.0820312 8.48047 1.61328 6.94922Z" />
  </svg>
);

export const ICEllipsis = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.3125 2.25C15.3125 2.88281 14.9609 3.41016 14.4688 3.72656C13.9414 4.04297 13.2734 4.04297 12.7812 3.72656C12.2539 3.41016 11.9375 2.88281 11.9375 2.25C11.9375 1.65234 12.2539 1.125 12.7812 0.808594C13.2734 0.492188 13.9414 0.492188 14.4688 0.808594C14.9609 1.125 15.3125 1.65234 15.3125 2.25ZM9.6875 2.25C9.6875 2.88281 9.33594 3.41016 8.84375 3.72656C8.31641 4.04297 7.64844 4.04297 7.15625 3.72656C6.62891 3.41016 6.3125 2.88281 6.3125 2.25C6.3125 1.65234 6.62891 1.125 7.15625 0.808594C7.64844 0.492188 8.31641 0.492188 8.84375 0.808594C9.33594 1.125 9.6875 1.65234 9.6875 2.25ZM2.375 3.9375C1.74219 3.9375 1.21484 3.62109 0.898438 3.09375C0.582031 2.60156 0.582031 1.93359 0.898438 1.40625C1.21484 0.914062 1.74219 0.5625 2.375 0.5625C2.97266 0.5625 3.5 0.914062 3.81641 1.40625C4.13281 1.93359 4.13281 2.60156 3.81641 3.09375C3.5 3.62109 2.97266 3.9375 2.375 3.9375Z" />
  </svg>
);

export const ICChevronLeft = (props: any) => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.910156 6.28516L6.16016 1.0625C6.40625 0.789062 6.81641 0.789062 7.08984 1.0625C7.33594 1.30859 7.33594 1.71875 7.08984 1.96484L2.27734 6.75L7.0625 11.5625C7.33594 11.8086 7.33594 12.2188 7.0625 12.4648C6.81641 12.7383 6.40625 12.7383 6.16016 12.4648L0.910156 7.21484C0.636719 6.96875 0.636719 6.55859 0.910156 6.28516Z" />
  </svg>
);

export const ICChevronRight = (props: any) => (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.14844 5.60156C6.35938 5.83594 6.35938 6.1875 6.14844 6.39844L1.64844 10.8984C1.41406 11.1328 1.0625 11.1328 0.851562 10.8984C0.617188 10.6875 0.617188 10.3359 0.851562 10.125L4.95312 6.02344L0.851562 1.89844C0.617188 1.6875 0.617188 1.33594 0.851562 1.125C1.0625 0.890625 1.41406 0.890625 1.625 1.125L6.14844 5.60156Z"
      fill="#8A92A6"
    />
  </svg>
);

export const ICLock = (props: any) => (
  <svg
    width="35"
    height="40"
    viewBox="0 0 35 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.25 10V15H23.75V10C23.75 6.5625 20.9375 3.75 17.5 3.75C13.9844 3.75 11.25 6.5625 11.25 10ZM7.5 15V10C7.5 4.53125 11.9531 0 17.5 0C22.9688 0 27.5 4.53125 27.5 10V15H30C32.7344 15 35 17.2656 35 20V35C35 37.8125 32.7344 40 30 40H5C2.1875 40 0 37.8125 0 35V20C0 17.2656 2.1875 15 5 15H7.5ZM3.75 20V35C3.75 35.7031 4.29688 36.25 5 36.25H30C30.625 36.25 31.25 35.7031 31.25 35V20C31.25 19.375 30.625 18.75 30 18.75H5C4.29688 18.75 3.75 19.375 3.75 20Z" />
  </svg>
);

export const ICArrowUpLeft = (props: any) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 14.75C5.48438 14.75 3.1875 13.4375 1.92969 11.25C0.671875 9.08984 0.671875 6.4375 1.92969 4.25C3.1875 2.08984 5.48438 0.75 8 0.75C10.4883 0.75 12.7852 2.08984 14.043 4.25C15.3008 6.4375 15.3008 9.08984 14.043 11.25C12.7852 13.4375 10.4883 14.75 8 14.75ZM9.75 4.6875H5.59375C5.21094 4.6875 4.9375 4.98828 4.9375 5.34375V9.71875C4.9375 10.1016 5.21094 10.375 5.59375 10.375C5.94922 10.375 6.25 10.1016 6.25 9.71875V6.92969L9.94141 10.6211C10.1875 10.8945 10.5977 10.8945 10.8711 10.6211C11.1172 10.375 11.1172 9.96484 10.8711 9.69141L7.15234 6H9.75C10.1055 6 10.4062 5.72656 10.4062 5.34375C10.4062 4.98828 10.1055 4.6875 9.75 4.6875Z" />
  </svg>
);

export const ICArrowUpRIght = (props: any) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 7.75C0 5.26172 1.3125 2.96484 3.5 1.70703C5.66016 0.449219 8.3125 0.449219 10.5 1.70703C12.6602 2.96484 14 5.26172 14 7.75C14 10.2656 12.6602 12.5625 10.5 13.8203C8.3125 15.0781 5.66016 15.0781 3.5 13.8203C1.3125 12.5625 0 10.2656 0 7.75ZM10.0625 9.5V5.34375C10.0625 4.98828 9.76172 4.6875 9.40625 4.6875H5.03125C4.64844 4.6875 4.375 4.98828 4.375 5.34375C4.375 5.72656 4.64844 6 5.03125 6L7.82031 6.02734L4.12891 9.71875C3.85547 9.96484 3.85547 10.375 4.12891 10.6211C4.375 10.8945 4.78516 10.8945 5.05859 10.6211L8.75 6.92969V9.5C8.75 9.88281 9.02344 10.1562 9.40625 10.1562C9.76172 10.1562 10.0625 9.88281 10.0625 9.5Z"
      fill="#00643C"
    />
  </svg>
);

export const ICStars = (props: any) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1562 4.78125L10.75 3.25L9.21875 2.84375C9.09375 2.78125 9 2.65625 9 2.5C9 2.375 9.09375 2.25 9.21875 2.1875L10.75 1.75L11.1562 0.25C11.2188 0.125 11.3438 0 11.5 0C11.625 0 11.75 0.125 11.8125 0.25L12.25 1.75L13.75 2.1875C13.875 2.25 14 2.375 14 2.5C14 2.65625 13.875 2.78125 13.75 2.84375L12.25 3.25L11.8125 4.78125C11.75 4.90625 11.625 5 11.5 5C11.3438 5 11.2188 4.90625 11.1562 4.78125ZM7.65625 4.9375L9.09375 7.875L12.3438 8.34375C12.9375 8.4375 13.1875 9.1875 12.75 9.625L10.4062 11.9062L10.9688 15.125C11.0625 15.75 10.4375 16.2188 9.875 15.9375L6.96875 14.4062L4.09375 15.9375C3.53125 16.2188 2.90625 15.75 3 15.125L3.5625 11.9062L1.21875 9.625C0.78125 9.1875 1 8.4375 1.625 8.34375L4.875 7.875L6.3125 4.9375C6.59375 4.375 7.375 4.375 7.65625 4.9375ZM14.375 7.625L13.1875 7.28125C13.0625 7.25 13 7.125 13 7C13 6.90625 13.0625 6.78125 13.1875 6.75L14.375 6.40625L14.7188 5.21875C14.75 5.09375 14.875 5 15 5C15.0938 5 15.2188 5.09375 15.25 5.21875L15.5938 6.40625L16.7812 6.75C16.9062 6.78125 17 6.90625 17 7C17 7.125 16.9062 7.25 16.7812 7.28125L15.5938 7.625L15.25 8.8125C15.2188 8.9375 15.0938 9 15 9C14.875 9 14.75 8.9375 14.7188 8.8125L14.375 7.625Z"
      fill="#00643C"
    />
  </svg>
);

export const ICHastagLock = (props: any) => (
  <svg
    width="45"
    height="38"
    viewBox="0 0 45 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.6875 0.578125C15.7031 0.8125 16.4062 1.75 16.1719 2.76562L15 8.625H23.6719L25 2.0625C25.2344 1.04688 26.1719 0.34375 27.1875 0.578125C28.2031 0.8125 28.9062 1.75 28.6719 2.76562L27.5 8.625H33.125C34.1406 8.625 35 9.48438 35 10.5C35 10.5781 35 10.5781 35 10.6562C33.4375 10.8125 31.9531 11.5156 30.7812 12.375H26.7188L22.4219 34.0156C22.1875 35.0312 21.25 35.7344 20.2344 35.5C19.2188 35.2656 18.5156 34.3281 18.75 33.3125L19.9219 27.375H11.25L9.92188 34.0156C9.76562 35.0312 8.75 35.7344 7.73438 35.5C6.71875 35.2656 6.09375 34.3281 6.25 33.3125L7.42188 27.375H1.875C0.78125 27.375 0 26.5938 0 25.5C0 24.4844 0.78125 23.625 1.875 23.625H8.20312L10.3906 12.375H4.375C3.28125 12.375 2.5 11.5938 2.5 10.5C2.5 9.48438 3.28125 8.625 4.375 8.625H11.1719L12.5 2.0625C12.7344 1.04688 13.6719 0.34375 14.6875 0.578125ZM14.2188 12.375L12.0312 23.625H20.7031L22.8906 12.375H14.2188ZM36.25 16.75C34.8438 16.75 33.75 17.9219 33.75 19.25V23H38.75V19.25C38.75 17.9219 37.5781 16.75 36.25 16.75ZM30 19.25C30 15.8125 32.7344 13 36.25 13C39.6875 13 42.5 15.8125 42.5 19.25V23C43.8281 23 45 24.1719 45 25.5V35.5C45 36.9062 43.8281 38 42.5 38H30C28.5938 38 27.5 36.9062 27.5 35.5V25.5C27.5 24.1719 28.5938 23 30 23V19.25Z" />
  </svg>
);
export const ICTweenie = (props: any) => (
  <svg
    width="74"
    height="17"
    viewBox="0 0 74 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6464_127)">
      <path d="M0 0H3.28226V3.27381H0V0Z" fill="#7101FF" />
      <path d="M0 6.54762H3.28226V9.82143H0V6.54762Z" fill="#7101FF" />
      <path d="M0 13.0952H3.28226V16.369H0V13.0952Z" fill="#7101FF" />
      <path
        d="M3.28226 13.0952H6.56452V16.369H3.28226V13.0952Z"
        fill="#7101FF"
      />
      <path d="M0 9.82143H3.28226V13.0952H0V9.82143Z" fill="#7101FF" />
      <path
        d="M3.28226 3.27381H6.56452V6.54762H3.28226V3.27381Z"
        fill="#7101FF"
      />
      <path
        d="M9.01074 6.94047H11.5709L13.619 13.5405H13.6584L15.5687 6.94047H18.1289L20.177 13.5405H20.2164L22.1857 6.94047H24.5686L21.3586 16.369H19.0151L16.77 9.92619H16.7306L14.7416 16.369H12.2996L9.01074 6.94047Z"
        fill="black"
      />
      <path
        d="M27.8555 12.4798C27.9342 13.1869 28.21 13.7369 28.6826 14.1298C29.1552 14.5226 29.7264 14.719 30.3959 14.719C30.9867 14.719 31.4791 14.6012 31.873 14.3655C32.28 14.1167 32.6344 13.8089 32.9364 13.4423L34.6301 14.719C34.0786 15.4 33.4616 15.8845 32.7789 16.1726C32.0961 16.4607 31.3806 16.6048 30.6323 16.6048C29.9233 16.6048 29.2537 16.4869 28.6235 16.2512C27.9933 16.0155 27.4485 15.6815 26.989 15.2494C26.5294 14.8173 26.1618 14.3 25.8861 13.6976C25.6235 13.0821 25.4922 12.4012 25.4922 11.6548C25.4922 10.9083 25.6235 10.2339 25.8861 9.63154C26.1618 9.01607 26.5294 8.49226 26.989 8.06012C27.4485 7.62797 27.9933 7.29404 28.6235 7.05833C29.2537 6.82262 29.9233 6.70476 30.6323 6.70476C31.2887 6.70476 31.8861 6.82262 32.4244 7.05833C32.9758 7.28095 33.4419 7.60833 33.8226 8.04047C34.2165 8.47262 34.5185 9.00952 34.7285 9.65119C34.9517 10.2798 35.0633 11.0065 35.0633 11.8315V12.4798H27.8555ZM32.7001 10.7119C32.687 10.0179 32.4703 9.4744 32.0502 9.08154C31.6301 8.67559 31.0458 8.47262 30.2975 8.47262C29.5885 8.47262 29.024 8.67559 28.6038 9.08154C28.1968 9.4875 27.9474 10.031 27.8555 10.7119H32.7001Z"
        fill="black"
      />
      <path
        d="M39.1639 12.4798C39.2426 13.1869 39.5184 13.7369 39.991 14.1298C40.4636 14.5226 41.0348 14.719 41.7043 14.719C42.2951 14.719 42.7875 14.6012 43.1814 14.3655C43.5884 14.1167 43.9428 13.8089 44.2448 13.4423L45.9385 14.719C45.387 15.4 44.77 15.8845 44.0873 16.1726C43.4046 16.4607 42.689 16.6048 41.9407 16.6048C41.2317 16.6048 40.5621 16.4869 39.9319 16.2512C39.3017 16.0155 38.7569 15.6815 38.2974 15.2494C37.8378 14.8173 37.4702 14.3 37.1945 13.6976C36.9319 13.0821 36.8006 12.4012 36.8006 11.6548C36.8006 10.9083 36.9319 10.2339 37.1945 9.63154C37.4702 9.01607 37.8378 8.49226 38.2974 8.06012C38.7569 7.62797 39.3017 7.29404 39.9319 7.05833C40.5621 6.82262 41.2317 6.70476 41.9407 6.70476C42.5971 6.70476 43.1945 6.82262 43.7328 7.05833C44.2842 7.28095 44.7503 7.60833 45.131 8.04047C45.5249 8.47262 45.8269 9.00952 46.0369 9.65119C46.2601 10.2798 46.3717 11.0065 46.3717 11.8315V12.4798H39.1639ZM44.0085 10.7119C43.9954 10.0179 43.7787 9.4744 43.3586 9.08154C42.9385 8.67559 42.3542 8.47262 41.6059 8.47262C40.8969 8.47262 40.3324 8.67559 39.9122 9.08154C39.5052 9.4875 39.2558 10.031 39.1639 10.7119H44.0085Z"
        fill="black"
      />
      <path
        d="M48.5817 6.94047H50.8268V8.45297H50.8661C51.0893 7.98154 51.4307 7.57559 51.8902 7.23512C52.3497 6.88155 52.9734 6.70476 53.7611 6.70476C54.3913 6.70476 54.923 6.80952 55.3563 7.01904C55.8027 7.22857 56.1637 7.50357 56.4394 7.84405C56.7151 8.18452 56.9121 8.57738 57.0302 9.02262C57.1484 9.46785 57.2075 9.93274 57.2075 10.4173V16.369H54.8442V11.5958C54.8442 11.3339 54.8311 11.0458 54.8049 10.7315C54.7786 10.4042 54.7064 10.103 54.5882 9.82797C54.4832 9.53988 54.3125 9.30416 54.0762 9.12083C53.8399 8.9244 53.5182 8.82619 53.1112 8.82619C52.7173 8.82619 52.3826 8.89166 52.1068 9.02262C51.8443 9.15357 51.6211 9.33035 51.4373 9.55297C51.2666 9.77559 51.1419 10.0309 51.0631 10.319C50.9843 10.6071 50.9449 10.9083 50.9449 11.2226V16.369H48.5817V6.94047Z"
        fill="black"
      />
      <path
        d="M59.9295 6.94047H62.2927V16.369H59.9295V6.94047ZM59.5947 3.73869C59.5947 3.35893 59.7326 3.03154 60.0083 2.75655C60.2971 2.46845 60.6581 2.3244 61.0914 2.3244C61.5247 2.3244 61.8857 2.4619 62.1746 2.7369C62.4765 2.99881 62.6275 3.33274 62.6275 3.73869C62.6275 4.14464 62.4765 4.48512 62.1746 4.76012C61.8857 5.02202 61.5247 5.15297 61.0914 5.15297C60.6581 5.15297 60.2971 5.01547 60.0083 4.74047C59.7326 4.45238 59.5947 4.11845 59.5947 3.73869Z"
        fill="black"
      />
      <path
        d="M66.8964 12.4798C66.9752 13.1869 67.2509 13.7369 67.7235 14.1298C68.1962 14.5226 68.7673 14.719 69.4369 14.719C70.0277 14.719 70.52 14.6012 70.9139 14.3655C71.3209 14.1167 71.6754 13.8089 71.9773 13.4423L73.671 14.719C73.1196 15.4 72.5025 15.8845 71.8198 16.1726C71.1371 16.4607 70.4215 16.6048 69.6732 16.6048C68.9642 16.6048 68.2946 16.4869 67.6644 16.2512C67.0342 16.0155 66.4894 15.6815 66.0299 15.2494C65.5704 14.8173 65.2027 14.3 64.927 13.6976C64.6645 13.0821 64.5332 12.4012 64.5332 11.6548C64.5332 10.9083 64.6645 10.2339 64.927 9.63154C65.2027 9.01607 65.5704 8.49226 66.0299 8.06012C66.4894 7.62797 67.0342 7.29404 67.6644 7.05833C68.2946 6.82262 68.9642 6.70476 69.6732 6.70476C70.3296 6.70476 70.927 6.82262 71.4653 7.05833C72.0167 7.28095 72.4828 7.60833 72.8635 8.04047C73.2574 8.47262 73.5594 9.00952 73.7694 9.65119C73.9926 10.2798 74.1042 11.0065 74.1042 11.8315V12.4798H66.8964ZM71.741 10.7119C71.7279 10.0179 71.5112 9.4744 71.0911 9.08154C70.671 8.67559 70.0867 8.47262 69.3384 8.47262C68.6294 8.47262 68.0649 8.67559 67.6447 9.08154C67.2377 9.4875 66.9883 10.031 66.8964 10.7119H71.741Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_6464_127">
        <rect width="74" height="16.6667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ICTweenieClassic = (props: any) => (
  <svg
    width="49"
    height="12"
    viewBox="0 0 49 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_8315_8871)">
      <path d="M0 0.5H2.15491V2.66071H0V0.5Z" fill="#656C7B" />
      <path d="M0 4.82143H2.15491V6.98214H0V4.82143Z" fill="#656C7B" />
      <path d="M0 9.14286H2.15491V11.3036H0V9.14286Z" fill="#656C7B" />
      <path
        d="M2.15491 9.14286H4.30981V11.3036H2.15491V9.14286Z"
        fill="#656C7B"
      />
      <path d="M0 6.98214H2.15491V9.14286H0V6.98214Z" fill="#656C7B" />
      <path
        d="M2.15491 2.66071H4.30981V4.82143H2.15491V2.66071Z"
        fill="#656C7B"
      />
      <path
        d="M5.91602 5.08079H7.59684L8.9415 9.43679H8.96736L10.2215 5.08079H11.9023L13.247 9.43679H13.2729L14.5658 5.08079H16.1303L14.0228 11.3036H12.4842L11.0102 7.05136H10.9844L9.67848 11.3036H8.07523L5.91602 5.08079Z"
        fill="#656C7B"
      />
      <path
        d="M18.2882 8.73671C18.3399 9.20343 18.5209 9.56643 18.8312 9.82571C19.1415 10.085 19.5165 10.2146 19.9561 10.2146C20.344 10.2146 20.6672 10.1369 20.9258 9.98129C21.193 9.81707 21.4257 9.61397 21.624 9.37197L22.7359 10.2146C22.3739 10.6641 21.9688 10.9839 21.5205 11.174C21.0723 11.3641 20.6025 11.4592 20.1112 11.4592C19.6458 11.4592 19.2062 11.3814 18.7924 11.2259C18.3787 11.0703 18.021 10.8499 17.7193 10.5647C17.4176 10.2795 17.1762 9.93807 16.9952 9.5405C16.8228 9.13429 16.7366 8.68486 16.7366 8.19222C16.7366 7.69957 16.8228 7.25447 16.9952 6.85689C17.1762 6.45068 17.4176 6.10497 17.7193 5.81975C18.021 5.53454 18.3787 5.31414 18.7924 5.15857C19.2062 5.003 19.6458 4.92522 20.1112 4.92522C20.5422 4.92522 20.9344 5.003 21.2878 5.15857C21.6498 5.3055 21.9558 5.52157 22.2058 5.80679C22.4644 6.092 22.6626 6.44636 22.8005 6.86986C22.9471 7.28472 23.0203 7.76439 23.0203 8.30889V8.73671H18.2882ZM21.4688 7.56993C21.4602 7.11186 21.318 6.75318 21.0421 6.49389C20.7663 6.22597 20.3827 6.092 19.8914 6.092C19.426 6.092 19.0553 6.22597 18.7795 6.49389C18.5123 6.76182 18.3485 7.1205 18.2882 7.56993H21.4688Z"
        fill="#656C7B"
      />
      <path
        d="M25.7125 8.73671C25.7642 9.20343 25.9452 9.56643 26.2555 9.82571C26.5658 10.085 26.9408 10.2146 27.3804 10.2146C27.7683 10.2146 28.0915 10.1369 28.3501 9.98129C28.6173 9.81707 28.85 9.61397 29.0483 9.37197L30.1602 10.2146C29.7982 10.6641 29.3931 10.9839 28.9449 11.174C28.4966 11.3641 28.0269 11.4592 27.5355 11.4592C27.0701 11.4592 26.6305 11.3814 26.2167 11.2259C25.803 11.0703 25.4453 10.8499 25.1436 10.5647C24.8419 10.2795 24.6006 9.93807 24.4196 9.5405C24.2472 9.13429 24.161 8.68486 24.161 8.19222C24.161 7.69957 24.2472 7.25447 24.4196 6.85689C24.6006 6.45068 24.8419 6.10497 25.1436 5.81975C25.4453 5.53454 25.803 5.31414 26.2167 5.15857C26.6305 5.003 27.0701 4.92522 27.5355 4.92522C27.9665 4.92522 28.3587 5.003 28.7121 5.15857C29.0742 5.3055 29.3801 5.52157 29.6301 5.80679C29.8887 6.092 30.087 6.44636 30.2249 6.86986C30.3714 7.28472 30.4447 7.76439 30.4447 8.30889V8.73671H25.7125ZM28.8931 7.56993C28.8845 7.11186 28.7423 6.75318 28.4665 6.49389C28.1906 6.22597 27.8071 6.092 27.3157 6.092C26.8503 6.092 26.4796 6.22597 26.2038 6.49389C25.9366 6.76182 25.7728 7.1205 25.7125 7.56993H28.8931Z"
        fill="#656C7B"
      />
      <path
        d="M31.8956 5.08079H33.3696V6.07904H33.3954C33.5419 5.76789 33.7661 5.49997 34.0677 5.27525C34.3694 5.04189 34.7789 4.92522 35.296 4.92522C35.7098 4.92522 36.0589 4.99436 36.3433 5.13264C36.6364 5.27093 36.8734 5.45243 37.0544 5.67714C37.2355 5.90186 37.3647 6.16114 37.4423 6.455C37.5199 6.74886 37.5587 7.05568 37.5587 7.37546V11.3036H36.0072V8.15332C36.0072 7.98046 35.9985 7.79032 35.9813 7.58289C35.9641 7.36682 35.9167 7.16804 35.8391 6.98654C35.7701 6.79639 35.6581 6.64082 35.5029 6.51982C35.3478 6.39018 35.1366 6.32536 34.8694 6.32536C34.6108 6.32536 34.391 6.36857 34.21 6.455C34.0376 6.54143 33.891 6.65811 33.7704 6.80504C33.6583 6.95197 33.5764 7.1205 33.5247 7.31064C33.473 7.50079 33.4471 7.69957 33.4471 7.907V11.3036H31.8956V5.08079Z"
        fill="#656C7B"
      />
      <path
        d="M39.3458 5.08079H40.8973V11.3036H39.3458V5.08079ZM39.126 2.96761C39.126 2.71697 39.2165 2.50089 39.3975 2.31939C39.5871 2.12925 39.8242 2.03418 40.1086 2.03418C40.3931 2.03418 40.6301 2.12493 40.8197 2.30643C41.018 2.47929 41.1171 2.69968 41.1171 2.96761C41.1171 3.23554 41.018 3.46025 40.8197 3.64175C40.6301 3.81461 40.3931 3.90104 40.1086 3.90104C39.8242 3.90104 39.5871 3.81029 39.3975 3.62879C39.2165 3.43864 39.126 3.21825 39.126 2.96761Z"
        fill="#656C7B"
      />
      <path
        d="M43.9198 8.73671C43.9715 9.20343 44.1525 9.56643 44.4628 9.82571C44.7731 10.085 45.1481 10.2146 45.5877 10.2146C45.9755 10.2146 46.2988 10.1369 46.5574 9.98129C46.8246 9.81707 47.0573 9.61397 47.2556 9.37197L48.3675 10.2146C48.0055 10.6641 47.6003 10.9839 47.1521 11.174C46.7039 11.3641 46.2341 11.4592 45.7428 11.4592C45.2774 11.4592 44.8378 11.3814 44.424 11.2259C44.0103 11.0703 43.6526 10.8499 43.3509 10.5647C43.0492 10.2795 42.8078 9.93807 42.6268 9.5405C42.4544 9.13429 42.3682 8.68486 42.3682 8.19222C42.3682 7.69957 42.4544 7.25447 42.6268 6.85689C42.8078 6.45068 43.0492 6.10497 43.3509 5.81975C43.6526 5.53454 44.0103 5.31414 44.424 5.15857C44.8378 5.003 45.2774 4.92522 45.7428 4.92522C46.1738 4.92522 46.566 5.003 46.9194 5.15857C47.2814 5.3055 47.5874 5.52157 47.8374 5.80679C48.096 6.092 48.2942 6.44636 48.4321 6.86986C48.5787 7.28472 48.6519 7.76439 48.6519 8.30889V8.73671H43.9198ZM47.1004 7.56993C47.0918 7.11186 46.9496 6.75318 46.6737 6.49389C46.3979 6.22597 46.0143 6.092 45.523 6.092C45.0576 6.092 44.6869 6.22597 44.4111 6.49389C44.1439 6.76182 43.9801 7.1205 43.9198 7.56993H47.1004Z"
        fill="#656C7B"
      />
    </g>
    <defs>
      <clipPath id="clip0_8315_8871">
        <rect
          width="48.5833"
          height="11"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ICSearch = (props: any) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0625 5.9375C10.0625 4.37891 9.21484 2.95703 7.875 2.16406C6.50781 1.37109 4.83984 1.37109 3.5 2.16406C2.13281 2.95703 1.3125 4.37891 1.3125 5.9375C1.3125 7.52344 2.13281 8.94531 3.5 9.73828C4.83984 10.5312 6.50781 10.5312 7.875 9.73828C9.21484 8.94531 10.0625 7.52344 10.0625 5.9375ZM9.21484 10.4219C8.23047 11.1875 7 11.625 5.6875 11.625C2.54297 11.625 0 9.08203 0 5.9375C0 2.82031 2.54297 0.25 5.6875 0.25C8.80469 0.25 11.375 2.82031 11.375 5.9375C11.375 7.27734 10.9102 8.50781 10.1445 9.49219L13.8086 13.1289C14.0547 13.4023 14.0547 13.8125 13.8086 14.0586C13.5352 14.332 13.125 14.332 12.8789 14.0586L9.21484 10.4219Z"
      fill="#00643C"
    />
  </svg>
);

export const ICCircleXmark = (props: any) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M8 14.25C5.48438 14.25 3.1875 12.9375 1.92969 10.75C0.671875 8.58984 0.671875 5.9375 1.92969 3.75C3.1875 1.58984 5.48438 0.25 8 0.25C10.4883 0.25 12.7852 1.58984 14.043 3.75C15.3008 5.9375 15.3008 8.58984 14.043 10.75C12.7852 12.9375 10.4883 14.25 8 14.25ZM5.78516 5.03516C5.51172 5.30859 5.51172 5.71875 5.78516 5.96484L7.07031 7.25L5.78516 8.53516C5.51172 8.80859 5.51172 9.21875 5.78516 9.46484C6.03125 9.73828 6.44141 9.73828 6.6875 9.46484L7.97266 8.17969L9.25781 9.46484C9.53125 9.73828 9.94141 9.73828 10.1875 9.46484C10.4609 9.21875 10.4609 8.80859 10.1875 8.53516L8.90234 7.25L10.1875 5.96484C10.4609 5.71875 10.4609 5.30859 10.1875 5.03516C9.94141 4.78906 9.53125 4.78906 9.25781 5.03516L7.97266 6.32031L6.6875 5.03516C6.44141 4.78906 6.03125 4.78906 5.78516 5.03516Z"
      fill="#00643C"
    />
  </svg>
);

export const ICUpRightFrom = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 0H15C15.5312 0 16 0.46875 16 1V5C16 5.40625 15.75 5.78125 15.375 5.9375C15 6.09375 14.5625 6 14.2812 5.71875L13 4.4375L7.6875 9.71875C7.3125 10.125 6.65625 10.125 6.28125 9.71875C5.875 9.34375 5.875 8.6875 6.28125 8.3125L11.5625 3L10.2812 1.71875C10 1.4375 9.90625 1 10.0625 0.625C10.2188 0.25 10.5938 0 11 0ZM2.5 1H6C6.53125 1 7 1.46875 7 2C7 2.5625 6.53125 3 6 3H2.5C2.21875 3 2 3.25 2 3.5V13.5C2 13.7812 2.21875 14 2.5 14H12.5C12.75 14 13 13.7812 13 13.5V10C13 9.46875 13.4375 9 14 9C14.5312 9 15 9.46875 15 10V13.5C15 14.9062 13.875 16 12.5 16H2.5C1.09375 16 0 14.9062 0 13.5V3.5C0 2.125 1.09375 1 2.5 1Z"
      fill="#656C7B"
    />
  </svg>
);

export const ICExpand = (props: any) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.83203 0.75H12.9062C13.5078 0.75 14 1.24219 14 1.84375V5.91797C14 6.46484 13.5625 6.875 13.0156 6.875C12.7695 6.875 12.5234 6.79297 12.332 6.60156L11.5938 5.83594L9.89844 7.53125C9.37891 8.05078 8.53125 8.05078 8.03906 7.53125L7.21875 6.71094C6.69922 6.21875 6.69922 5.37109 7.21875 4.87891L8.91406 3.15625L8.14844 2.41797C7.95703 2.22656 7.875 1.98047 7.875 1.73438C7.875 1.1875 8.28516 0.75 8.83203 0.75ZM10.3086 2.69141L10.2812 2.71875C10.5547 2.96484 10.5547 3.375 10.2812 3.62109L8.12109 5.78125L8.96875 6.62891L11.1289 4.46875C11.375 4.19531 11.7852 4.19531 12.0586 4.46875L12.6875 5.09766V2.0625H9.67969L10.3086 2.69141ZM5.14062 14.75H1.09375C0.464844 14.75 0 14.2852 0 13.6562V9.60938C0 9.0625 0.410156 8.625 0.957031 8.625C1.20312 8.625 1.44922 8.73438 1.64062 8.92578L2.40625 9.69141L4.10156 7.96875C4.59375 7.47656 5.44141 7.47656 5.93359 7.96875L6.78125 8.78906C7.27344 9.30859 7.27344 10.1562 6.78125 10.6484L5.05859 12.3438L5.82422 13.1094C6.01562 13.3008 6.125 13.5469 6.125 13.793C6.125 14.3398 5.6875 14.75 5.14062 14.75ZM3.69141 12.8086C3.41797 12.5625 3.41797 12.1523 3.69141 11.8789L5.85156 9.71875L5.00391 8.89844L2.84375 11.0586C2.59766 11.332 2.1875 11.332 1.94141 11.0586L1.3125 10.4297V13.4375H4.32031L3.69141 12.8086Z"
      fill="#00643C"
    />
  </svg>
);

export const ICShrink = (props: any) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.09375 7.75C8.46484 7.75 8 7.28516 8 6.65625V2.60938C8 2.0625 8.41016 1.625 8.95703 1.625C9.20312 1.625 9.44922 1.73438 9.64062 1.92578L10.4062 2.69141L11.8828 1.1875C12.375 0.695312 13.2227 0.695312 13.7148 1.1875L14.5625 2.00781C15.0547 2.52734 15.0547 3.375 14.5625 3.86719L13.0586 5.34375L13.8242 6.10938C14.0156 6.30078 14.125 6.54688 14.125 6.79297C14.125 7.33984 13.6875 7.75 13.1406 7.75H9.09375ZM11.6914 5.80859C11.418 5.5625 11.418 5.15234 11.6914 4.87891L13.6328 2.9375L12.7852 2.11719L10.8438 4.05859C10.5977 4.33203 10.1875 4.33203 9.94141 4.05859L9.3125 3.42969V6.4375H12.3203L11.6914 5.80859ZM2.83203 7.75H6.90625C7.50781 7.75 8 8.24219 8 8.84375V12.918C8 13.4648 7.5625 13.875 7.01562 13.875C6.76953 13.875 6.52344 13.793 6.33203 13.6016L5.59375 12.8359L4.11719 14.3125C3.59766 14.832 2.75 14.832 2.25781 14.3125L1.4375 13.4922C0.917969 13 0.917969 12.1523 1.4375 11.6602L2.91406 10.1562L2.14844 9.41797C1.95703 9.22656 1.875 8.98047 1.875 8.73438C1.875 8.1875 2.28516 7.75 2.83203 7.75ZM4.30859 9.69141L4.28125 9.71875C4.55469 9.96484 4.55469 10.375 4.28125 10.6211L2.33984 12.5625L3.1875 13.4102L5.12891 11.4688C5.375 11.1953 5.78516 11.1953 6.05859 11.4688L6.6875 12.0977V9.0625H3.67969L4.30859 9.69141Z"
      fill="#00643C"
    />
  </svg>
);

export const ICHide = (props: any) => (
  <svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.92188 12.0781L0.921875 3.07812C0.3125 2.51562 0.3125 1.53125 0.921875 0.96875C1.48438 0.359375 2.46875 0.359375 3.03125 0.96875L11 8.89062L18.9219 0.96875C19.4844 0.359375 20.4688 0.359375 21.0312 0.96875C21.6406 1.53125 21.6406 2.51562 21.0312 3.07812L12.0312 12.0781C11.4688 12.6875 10.4844 12.6875 9.92188 12.0781Z"
      fill="#00643C"
    />
  </svg>
);
