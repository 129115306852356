const checkIfErrorOccurs = (res: any) => ({
  code: res.status,
  res,
});

const TIME_OUT = 60000;

async function customFetch(path: string, headerOptions: any) {
  const normalFetch = fetch(path, headerOptions);

  const res: any = await timeoutPromise(
    TIME_OUT,
    normalFetch.then(checkIfErrorOccurs).catch(checkIfErrorOccurs)
  );

  if (!res.code) {
    const error = {
      code: 404,
      message: 'Fail to fetch',
    };
    throw error;
  }

  if (res.code < 300) {
    const response = await res.res.text();
    try {
      const jsonResponse = JSON.parse(response);
      return jsonResponse;
    } catch (error) {
      return response;
    }
  }
  try {
    const response = await res.res.json();
    const error = {
      code: res.code,
      ...response,
    };
    return error;
  } catch (e: any) {
    if (res.code === 426) {
      const error = {
        code: res.code,
        message:
          'We have had some significant upgrades for the app. Please click below to upgrade your app!',
      };
      return error;
    } else {
      const error = {
        code: res.code,
        message:
          e.error || e.message || e.detail
            ? e.message || e.error || e.detail
            : 'Something wrong. Please try again.',
      };
      return error;
    }
  }
}

export const timeoutPromise = (ms: number, promise: any) =>
  new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request time out! Please try again.'));
    }, ms);
    promise.then(
      (res: any) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err: any) => {
        clearTimeout(timeoutId);
        reject(err);
      }
    );
  });

export default customFetch;

const privateApis = ['/conversations'];

function requestWrapper(method: string) {
  const request = async (
    url: string,
    data: any = null,
    params = {},
    prefix = ''
  ) => {
    let convertUrl = `${process.env.REACT_APP_CHAT_GPT_PUBLIC_API_URL}${prefix}${url}`;
    //  let convertParams = params;
    let convertData: any = data;
    if (method === 'GET') {
      // is it a GET?
      // GET doesn't have data
      // convertParams = convertData;
      if (convertData !== null) {
        convertUrl = `${convertUrl}${
          getQueryString(convertData)
            ? `?${getQueryString(convertData)}`
            : getQueryString(convertData)
        }`;
      }
      convertData = null;
    } else if (convertData === Object(convertData)) {
      convertData = JSON.stringify(convertData);
    }

    const defaults: Record<string, any> = {
      method,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      } as Record<string, any>,
    };

    const token = localStorage.getItem(
      `widget_session_token_${window.devtifyId}`
    );
    if (token && privateApis.find((e) => url?.includes(e))) {
      defaults.headers.Authorization = `Bearer ${token}`;
    }

    if (method === 'POST' || method === 'PUT') {
      defaults.headers.Accept = 'application/json';
      defaults.headers['Content-Type'] = 'application/json';
    }

    if (convertData) {
      defaults.body = convertData;
    }

    const paramsObj = {
      ...defaults,
      headers: { ...params, ...defaults.headers },
    };
    return customFetch(convertUrl, paramsObj);
  };
  return request;
}

export function getQueryString(params: any) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .filter((k) => params[k] || params[k] === 0)
    .map((k) => {
      const tmp: string[] = [];
      if (Array.isArray(params[k])) {
        params[k].forEach((k1: any) => {
          tmp.push(`${esc(k)}=${esc(k1)}`);
        });
        return tmp.join('&');
      } else {
        return `${esc(k)}=${esc(params[k])}`;
      }
    })
    .join('&');
}
export const get = requestWrapper('GET');
export const post = requestWrapper('POST');
export const put = requestWrapper('PUT');
export const patch = requestWrapper('PATCH');
export const del = requestWrapper('DELETE');
