import React from "react";

export default function LottieIcon({ size = 40 }) {
  return (
    <img
      src={`${process.env.REACT_APP_WIDGET_HOST || ""}/chatboxlogo.gif`}
      height={size}
      width={size}
      alt="diaflow-icon"
    />
  );
  // return <Lottie options={defaultOptions} height={size} width={size} />;
}
